
import { Alert, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from "react-i18next";
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from "../../context/ApplicationContext";
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from "../../utils/CommonFunctions";
import Loading from "../../components/Loading";
import ULButton from "../../components/ULButton";
import ModalityAndCareerContext from "../../context/ModalityAndCareerContext";
import EducationContext from "../../context/EducationContext";

const CareersFlow = (props) =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { applicationID } = useContext(ApplicationContext);
    const { modalityID, setModalityID, setCareerID, setUniversityData, requiresUniversity, setRequiresUniversity } = useContext(ModalityAndCareerContext);
    const { fifthGradeSchool, fourthGradeSchool, thirdGradeSchool } = useContext(EducationContext);

    const [modalitiesList, setModalitiesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessages, setErrMessages] = useState([]);
    const [working, setWorking] = useState(false);
    const [canEdit, setCanEdit] = useState(true);

    const handleContinue = (e) =>
    {
        if (canEdit)
            saveModalityAndCareerInfo(e);
        else
            navigateTo()
    }

    const navigateTo = () =>
    {
        if (requiresUniversity)
            navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_TYPE);
        else
            navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_CAREER);
    }

    function saveModalityAndCareerInfo(e)
    {
        const data = {
            "applicationId": applicationID,
            "callModalityId": modalityID,
        }
        setWorking(true);
        setErrMessages([]);
        APIServices.saveModalityAndCareerInfo(data)
            .then(() =>
            {
                navigateTo();
            })
            .catch((error) =>
            {
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorking(false);
            })
    }

    const fetchData = async () =>
    {
        if (!applicationID) return;
        setIsLoading(true);
        await APIServices.fetchModalityAndCareerInfo(applicationID)
            .then((response) =>
            {
                const data = response.data.data;
                if (data)
                {
                    setCareerID(data.callCareerId ?? 0);
                    setModalityID(data.callModalityId ?? 0);
                    data.university && setUniversityData(data.university);
                    setRequiresUniversity(data.requiresUniversity ?? false);
                    const status = response.data.data?.status;
                    setCanEdit(canUserEditInformation(status));
                }
            })
            .catch((error) =>
            {
                // No se pudo obtener la info (aún no ha sido registrada)
            })
            .finally(() =>
            {
                setIsLoading(false);
            })
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getModalityList(applicationID).then((response) =>
            {
                setModalitiesList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() =>
                {
                    setIsLoading(false);
                })
            await fetchData();
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleModalityChange = (e) =>
    {
        setModalityID(parseInt(e.target.value));
        const requiresUniversity = modalitiesList.find(item => item.callModalityId === parseInt(e.target.value))?.requiresUniversity ?? false;
        setRequiresUniversity(requiresUniversity);
    }

    const handleGoBack = () =>
    {
        if (fifthGradeSchool.schoolId === fourthGradeSchool.schoolId &&
            fifthGradeSchool.schoolId === thirdGradeSchool.schoolId &&
            fifthGradeSchool.sameSchool)
        {
            navigate(ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE);
        }
        else if (fourthGradeSchool.schoolId === thirdGradeSchool.schoolId &&
            fourthGradeSchool.sameSchool)
        {
            navigate(ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE);
        }
        else
        {
            navigate(ROUTES.EDUCATION_THIRD_SCHOOL_TYPE);
        }
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('modalities-and-careers.modalities-title')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        value={modalityID}
                                    >
                                        {
                                            modalitiesList?.map((modality, index) => (
                                                <FormControlLabel className="radio-button" onChange={handleModalityChange} disabled={!canEdit} key={index} value={modality.callModalityId} control={<Radio />} label={modality.name} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton
                                    working={working}
                                    onClick={handleContinue}
                                    disabled={!modalityID}
                                >
                                    {t('buttons.continue')}
                                </ULButton>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton
                                    variant="outlined"
                                    goBack={true}
                                    onClick={handleGoBack}
                                    fullWidth
                                >
                                    {t('buttons.back')}
                                </ULButton>
                            </Grid>

                        </Grid>
                    </>
            }
        </>
    )
}

export default CareersFlow;