import { createContext } from "react";
import { getDefaultSchoolData, getEducationLS, setEducationLS } from "../utils/CommonFunctions";
import { useState } from "react";
import { useEffect } from "react";

const EducationContext = createContext();
export default EducationContext;

export const EducationContextProvider = ({ children }) =>
{
    const [fifthGradeSchool, setFifthGradeSchool] = useState(getEducationLS('fifth'));
    const [fourthGradeSchool, setFourthGradeSchool] = useState(getEducationLS('fourth'));
    const [thirdGradeSchool, setThirdGradeSchool] = useState(getEducationLS('third'));

    useEffect(() =>
    {
        setEducationLS('fifth', fifthGradeSchool);
        setEducationLS('fourth', fourthGradeSchool);
        setEducationLS('third', thirdGradeSchool);
    }, [fifthGradeSchool, fourthGradeSchool, thirdGradeSchool]);

    const resetEducationContext = () =>
    {
        setFifthGradeSchool(getDefaultSchoolData());
        setFourthGradeSchool(getDefaultSchoolData());
        setThirdGradeSchool(getDefaultSchoolData());
    }

    return (
        <EducationContext.Provider value={{
            fifthGradeSchool: fifthGradeSchool,
            fourthGradeSchool: fourthGradeSchool,
            thirdGradeSchool: thirdGradeSchool,
            setFifthGradeSchool: setFifthGradeSchool,
            setFourthGradeSchool: setFourthGradeSchool,
            setThirdGradeSchool: setThirdGradeSchool,
            resetEducationContext: resetEducationContext,
        }}>
            {children}
        </EducationContext.Provider>
    )
}