import * as ROUTES from "../routes/routes";

export function formatDate (date)
{
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let stringDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    return stringDate;
}

export function formatDateHyphen (date)
{

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let stringDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

    return stringDate;
}

export function formatDateTime (date)
{
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${day}/${month}/${year} ${hour}:${minutes} ${timeHour}`;

    return stringDate;
}

export function formatDateTimeSecondsBackEnd (date)
{
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = date.getHours() === 0 || date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${day}/${month}/${year} ${hour}:${minutes}:${seconds} ${timeHour}`;

    return stringDate;
}

export function formatDateTimeSeconds (date)
{
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let hour = date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${day}/${month}/${year} ${hour}:${minutes}:${seconds} ${timeHour}`;

    return stringDate;
}

export function formatJustTime (date)
{
    let hour = date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

    let stringDate = `${hour} : ${minutes} : ${seconds}`;

    return stringDate;
}

export function formatJustTimeNoSeconds (date)
{
    let hour = date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    let stringDate = `${hour} : ${minutes} ${timeHour}`;

    return stringDate;
}

export function formatTime (date)
{
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

    let stringDate = `${hour}:${minutes}`;

    return stringDate;
}

export function formatSeconds (secondsInput)
{
    secondsInput = Math.floor(secondsInput);

    let hour = Math.floor(secondsInput / 3600);
    secondsInput = secondsInput - 3600 * hour;
    let minute = Math.floor(secondsInput / 60);
    secondsInput = secondsInput - 60 * minute;

    let hourString = hour < 10 ? '0' + hour : hour
    let minutesString = minute < 10 ? '0' + minute : minute
    let secondsString = secondsInput < 10 ? '0' + secondsInput : secondsInput

    let stringDate = `${hourString} : ${minutesString} : ${secondsString}`

    return stringDate
}

export function secondsOfTime (secondsInput)
{
    secondsInput = Math.floor(secondsInput);

    let hour = Math.floor(secondsInput / 3600);
    secondsInput = secondsInput - 3600 * hour;
    let minute = Math.floor(secondsInput / 60);
    secondsInput = secondsInput - 60 * minute;

    return secondsInput
}

export function formatNameCapitals (names)
{
    if (!names) return null;

    names = names.toLowerCase();
    var newNames = '';
    var last = ' ';

    for (var i = 0; i < names.length; i++)
    {
        if (last === ' ' || last === '(' || last === ')' || last === ',' || last === '.' || last === ';' || last === '/')
            newNames = newNames + names.charAt(i).toUpperCase();
        else newNames = newNames + names.charAt(i);

        last = names.charAt(i);
    }

    return newNames;
}

export function formatDateSpanish (date)
{

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours() === 12 ? '12' : date.getHours() % 12 < 10 ? '0' + (date.getHours() % 12) : date.getHours() % 12;
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let timeHour = date.getHours() >= 12 ? 'PM' : 'AM';

    const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];

    return `${hour}:${minutes} ${timeHour} del ${day} de ${months[month - 1]} del ${year}`;
}


export const ACCESS_TOKEN_LS_NAME = 'access_token'

export const getAccessToken = () =>
{
    return sessionStorage.getItem(ACCESS_TOKEN_LS_NAME);
}

export const setAccessToken = (token) =>
{
    sessionStorage.setItem(ACCESS_TOKEN_LS_NAME, token);
}

export const CALL_ID_LS_NAME = 'call_id';
export const APPLICATION_ID_LS_NAME = 'application_id';
export const VERIFICATION_CODE_VALIDATED_LS_NAME = 'verification_code_validated';
export const OMR_LS_NAME = 'omr';
export const CURRENT_STEP_LS_NAME = 'current_step';

export const EDUCATION_SCHOOL_LS_PREFIX = 'education_school_';
export const MODALITY_AND_CAREER_UNIVERSITY_LS_PREFIX = 'modality_and_career_university';
export const MODALITY_AND_CAREER_MODALITY_ID_LS_PREFIX = 'modality_and_career_modality_id';
export const MODALITY_AND_CAREER_CAREER_ID_LS_PREFIX = 'modality_and_career_career_id';
export const MODALITY_AND_CAREER_REQUIRES_UNIVERSITY_LS_PREFIX = 'modality_and_career_requires_university';

export const hasApplicationDataLS = () =>
{
    const callID = parseInt(sessionStorage.getItem(CALL_ID_LS_NAME) ?? 0);
    const applicationID = parseInt(sessionStorage.getItem(APPLICATION_ID_LS_NAME) ?? 0);
    return callID && applicationID;
}

export const getApplicationDataLS = () =>
{
    const callID = parseInt(sessionStorage.getItem(CALL_ID_LS_NAME) ?? 0);
    const applicationID = parseInt(sessionStorage.getItem(APPLICATION_ID_LS_NAME) ?? 0);
    const verificationCodeValidated = sessionStorage.getItem(VERIFICATION_CODE_VALIDATED_LS_NAME) === "true" ?? false;
    const omr = sessionStorage.getItem(OMR_LS_NAME) ?? "";
    return { _callID: callID, _applicationID: applicationID, _verificationCodeValidated: verificationCodeValidated, _omr: omr };
}

export const getDefaultSchoolData = () => ({
    schoolId: 0,
    schoolType: 0,
    schoolYear: 0,
    notFound: false,
    geolocation: '',
    applicantSchoolId: 0,
    sameSchool: false,
});

export const getEducationLS = (grade) =>
{
    const educationStr = sessionStorage.getItem(`${EDUCATION_SCHOOL_LS_PREFIX}${grade}`);
    return educationStr ? JSON.parse(educationStr) : getDefaultSchoolData();
}

export const setEducationLS = (grade, schoolData) =>
{
    sessionStorage.setItem(`${EDUCATION_SCHOOL_LS_PREFIX}${grade}`, JSON.stringify(schoolData));
}

export const getDefaultUniversityData = () => ({
    universityId: 0,
    universityType: 0,
    firstPeriodYear: 0,
    firstPeriodCycle: 0,
    lastPeriodYear: 0,
    lastPeriodCycle: 0,
    notFound: false,
    geolocation: "",
});

export const getRequiresUniversityLS = () =>
{
    const requiresUniversityStr = sessionStorage.getItem(MODALITY_AND_CAREER_REQUIRES_UNIVERSITY_LS_PREFIX);
    return requiresUniversityStr === 'true';
}

export const setRequiresUniversityLS = (requiresUniversity) =>
{
    sessionStorage.setItem(MODALITY_AND_CAREER_REQUIRES_UNIVERSITY_LS_PREFIX, requiresUniversity);
}

export const getUniversityLS = () =>
{
    const universityStr = sessionStorage.getItem(MODALITY_AND_CAREER_UNIVERSITY_LS_PREFIX);
    return universityStr ? JSON.parse(universityStr) : getDefaultUniversityData();
}

export const setUniversityLS = (universityData) =>
{
    sessionStorage.setItem(MODALITY_AND_CAREER_UNIVERSITY_LS_PREFIX, JSON.stringify(universityData));
}

export const getModalityIDLS = () =>
{
    return parseInt(sessionStorage.getItem(MODALITY_AND_CAREER_MODALITY_ID_LS_PREFIX) ?? 0);
}

export const setModalityIDLS = (modalityID) =>
{
    sessionStorage.setItem(MODALITY_AND_CAREER_MODALITY_ID_LS_PREFIX, modalityID);
}

export const getCareerIDLS = () =>
{
    return parseInt(sessionStorage.getItem(MODALITY_AND_CAREER_CAREER_ID_LS_PREFIX) ?? 0);
}

export const setCareerIDLS = (careerID) =>
{
    sessionStorage.setItem(MODALITY_AND_CAREER_CAREER_ID_LS_PREFIX, careerID);
}

export const setApplicationLS = (applicationID) =>
{
    sessionStorage.setItem(APPLICATION_ID_LS_NAME, applicationID);
}

export const setCallLS = (callID) =>
{
    sessionStorage.setItem(CALL_ID_LS_NAME, callID);
}

export const setOMRCodeLS = (omr) =>
{
    sessionStorage.setItem(OMR_LS_NAME, omr);
}

export const setVerificationCodeValidatedLS = (verificationCodeValidated) =>
{
    sessionStorage.setItem(VERIFICATION_CODE_VALIDATED_LS_NAME, verificationCodeValidated);
}

export const protectPhoneNumber = (phoneNumber) =>
{
    if (typeof phoneNumber === 'string' && /^\d{4,}$/.test(phoneNumber))
    {
        const last4digits = phoneNumber.slice(-4);
        // Completamos con asteriscos los dígitos anteriores
        const asteriscs = '*'.repeat(phoneNumber.length - 4);
        return `${asteriscs}${last4digits}`;
    }
    return 'Número de celular no válido';
}

export const protectEmail = (email) =>
{
    if (typeof email === 'string' &&
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))
    {
        const parts = email.split('@');
        if (parts.length === 2)
        {
            const user = parts[0];
            const domain = parts[1];
            if (user.length > 4)
            {
                const initChars = user.slice(0, 4);
                const asteriscs = '*'.repeat(user.length - 4);
                return initChars + asteriscs + '@' + domain;
            }
            return user + '@' + domain;
        }
    }
    return 'Correo electrónico no válido';
}


export const removeSchoolsWithZeroId = (obj) =>
{
    for (const key in obj)
    {
        if (obj[key].schoolId === 0)
            delete obj[key].schoolId;
    }
}

export const SCROLLABLE_AREA_NAME = "scrollableArea";
export const VALID_ID = 1;
export const STEP_IN_PROGRESS_ID = 1;
export const STEP_COMPLETED_ID = 2;


export const canUserEditInformation = (step) =>
{
    if (step === STEP_IN_PROGRESS_ID) return true;
    if (step === STEP_COMPLETED_ID) return false;
    return true;
}


export const stepRoute = (step) =>
{
    let route = '';
    switch (step)
    {
        case 0:
            route = ROUTES.STUDENT_IDENTIFICATION;
            break;
        case 1:
            route = ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE;
            break;
        case 2:
            route = ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY;
            break;
        case 3:
            route = ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS;
            break;
        case 4:
            route = ROUTES.PAYMENTS;
            break;
        case 5:
            route = ROUTES.DOCUMENT_VERIFICATION;
            break;
        case 6:
            route =ROUTES.APPLICANTS_CARD;
            break;
        // Customs
        case 10:
            // Navegar a datos personales, caso en que no se devuelve step en Student Identification
            route = ROUTES.STUDENT_PERSONAL_DATA
            break;
        default:
            break;
    }
    return route;
}

export const getCurrentStep = () =>
{
    return parseInt(sessionStorage.getItem(CURRENT_STEP_LS_NAME) ?? -1);
}

export const setCurrentStep = (step) =>
{
    sessionStorage.setItem(CURRENT_STEP_LS_NAME, step);
}

export const removeAccents = (str) =>
{
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
