import * as React from 'react';
import { Alert, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import * as APIServices from '../../services/rest/APIServices'
import { useContext } from 'react';
import ApplicationContext from '../../context/ApplicationContext';
import { SCROLLABLE_AREA_NAME, setCallLS } from '../../utils/CommonFunctions';
import Loading from '../../components/Loading';
import ULButton from '../../components/ULButton';
import EducationContext from '../../context/EducationContext';
import DocumentsContext from '../../context/DocumentsContext';
import ModalityAndCareerContext from '../../context/ModalityAndCareerContext';
import PersonalInformationContext from '../../context/PersonalInformationContext';

const NewApplication = (props) => {
    const { setCallID, resetApplicationContext } = useContext(ApplicationContext);
    const { resetDocumentsContext } = useContext(DocumentsContext);
    const { resetEducationContext } = useContext(EducationContext);
    const { resetModalityAndCareerContext } = useContext(ModalityAndCareerContext);
    const { resetPersonalInformationContext } = useContext(PersonalInformationContext);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [callID, setCallIDNA] = useState(-1);
    const [applicationsList, setApplicationsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessages, setErrMessages] = useState([]);

    const handleContinue = async () => {
        if (callID >= 0) {
            setCallID(parseInt(callID));
            setCallLS(parseInt(callID));
            navigate(ROUTES.STUDENT_IDENTIFICATION);
        }
    }

    function stepBack() {
        navigate(ROUTES.MENU);
    }

    React.useEffect(() => {
        const callServices = async () => {
            setIsLoading(true);
            await APIServices.getApplicationsList()
                .then((response) => {
                    let applicationsList = response.data.data?.list ?? [];
                    applicationsList.sort((a, b) => a.callId - b.callId)
                    setApplicationsList(applicationsList);
                })
                .catch((error) => {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        sessionStorage.clear();
        resetApplicationContext();
        resetDocumentsContext();
        resetPersonalInformationContext();
        resetModalityAndCareerContext();
        resetEducationContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column">
                            {
                                errMessages?.length ?
                                    <Grid item>
                                        <Alert role="alert" severity="error" style={{ marginBottom: '1rem' }}>
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item>
                                <div className="input-text">{t('new-application.subtitle')}</div>
                            </Grid>

                            <Grid item>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        onChange={(e) => {
                                            setCallIDNA(parseInt(e.target.value))
                                        }}
                                    >
                                        {
                                            applicationsList?.map((application, index) => (
                                                <FormControlLabel key={index} className="margin-height-auto radio-button" value={application.callId} control={<Radio />} label={<div className="margin-top-bottom"> <div className="input-text">{application.publicName}</div> <div className="helperText" style={{ color: '#FF5117' }}>{application.description}</div> </div>} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item className="min-space" >
                                <ULButton disabled={callID === -1} onClick={handleContinue}>{t('buttons.continue')}</ULButton>
                            </Grid>

                            <Grid item className="min-space" >
                                <ULButton variant="outlined" onClick={stepBack} goBack={true}>{t('buttons.back-to-menu')}</ULButton>
                            </Grid>
                        </Grid>
                    </>
            }
        </>
    )
}

export default NewApplication;