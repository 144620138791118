import { Alert, Card, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Routes, useNavigate } from 'react-router-dom';
import logo_light from '../../assets/images/logo_light.png';
import photo from '../../assets/images/photo.png';
import Barcode from 'react-barcode';
import ULButton from '../../components/ULButton';
import ApplicationContext from '../../context/ApplicationContext';
import * as APIServices from "../../services/rest/APIServices";
import { protectEmail, SCROLLABLE_AREA_NAME } from '../../utils/CommonFunctions';
import Loading from '../../components/Loading';


export default function ApplicantsCard()
{

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { applicationID } = React.useContext(ApplicationContext);
  const [errMessages, setErrMessages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [personalInfo, setPersonalInfo] = React.useState({
    name: "",
    firstSurname: "",
    secondSurname: "",
    documentType: "",
    documentNumber: "",
    email: "",
    uuid: ""
  });
  const [photo, setPhoto] = React.useState("");

  const clearApplication = () =>
  {
    sessionStorage.clear();
    window.location.href = '/';
  }

  const getApplicantDetails = () =>
  {
    setIsLoading(true);
    APIServices.fetchCard(applicationID)
      .then((response) =>
      {
        let applicantData = response.data.data;
        setPersonalInfo({
          name: applicantData.name ?? "",
          firstSurname: applicantData.firstSurname ?? "",
          secondSurname: applicantData.secondSurname ?? "",
          documentType: applicantData.documentType ?? "",
          documentNumber: applicantData.documentNumber ?? "",
          email: applicantData.email ?? "",
          uuid: applicantData.uuid ?? ""
        })
        APIServices.getCardPhoto(applicationID, applicantData.uuid)
          .then((response) =>
          {
            const imageContentType = 'image/jpeg';
            const imageBinary = new Blob([response.data], { type: imageContentType });
            const imageUrl = URL.createObjectURL(imageBinary);
            setPhoto(imageUrl);
          })
          .catch(error => console.error('Error al obtener la imagen:', error));
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setIsLoading(false);
      });
  }

  React.useEffect(() =>
  {
    getApplicantDetails();
  }, [])

  const handleDownload = (e) =>
  {
    e.target.disabled = true;
    const loadingSpinner = e.target.querySelector('span');
    loadingSpinner.classList.remove('hidden');
    setErrMessages([]);
    APIServices.downloadCard(applicationID)
      .then((response) =>
      {
        const fileContentType = response.headers['content-type'];
        const cardFilename = response.headers.ulima_filename;
        const cardBinary = new Blob([response.data], { type: fileContentType });
        const cardUrl = URL.createObjectURL(cardBinary);
        const dlink = document.createElement("a");
        dlink.href = cardUrl;
        dlink.download = cardFilename;
        dlink.target = "_blank";
        dlink.style.display = "none";
        document.body.appendChild(dlink);
        dlink.click();
        URL.revokeObjectURL(cardUrl);
        document.body.removeChild(dlink);
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        e.target.disabled = false;
        loadingSpinner.classList.add('hidden');
      })
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              <Grid item xs={12}>
                <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '16px' }}>
                  {t('applicants-card.title')}
                </Typography>
                <Typography variant="h5" component="h4" style={{ fontWeight: 500, fontSize: '20px' }}>
                  {t('applicants-card.applicants-card')}
                </Typography>
                <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                  {t('applicants-card.description-applicants-card')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      spacing={2}
                      item
                      xs={12}
                    >
                      <img
                        src={logo_light}
                        height={35}
                        alt={t('top-bar.ulima-logo-alt')}
                        loading="lazy"
                        style={{ margin: '10px 0px 0px 10px' }}
                      />
                    </Grid>
                    <Grid
                      spacing={2}
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={photo}
                        height={127}
                        alt={t('applicants-card.applicant-photo')}
                        loading="lazy"
                      />
                    </Grid>
                    <Grid
                      spacing={2}
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="h5" component="h4" style={{ fontWeight: 500, fontSize: '14px' }}>
                        {`${personalInfo.name} ${personalInfo.firstSurname} ${personalInfo.secondSurname}`}
                      </Typography>
                      <Typography variant="h5" component="h4" style={{ fontWeight: 300, fontSize: '12px', marginTop: '16px' }}>
                        {t('applicants-card.applicant')}
                      </Typography>
                      <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                        {t('applicants-card.code') + '999000'}
                      </Typography>
                      <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                        {t('applicants-card.classroom') + 'A1-401 / CARP: 026'}
                      </Typography>
                      <Typography variant="h5" component="h4" style={{ fontWeight: 400, fontSize: '14px' }}>
                        {t('applicants-card.valid') + '22/23'}
                      </Typography>
                      <Grid
                        spacing={2}
                        item
                        xs={12}
                        style={{ marginBottom: '8px' }}
                      >
                        <Barcode
                          width={2}
                          height={30}
                          value={12311212312}
                          displayValue={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <ULButton onClick={handleDownload} fullWidth>
                  {t('applicants-card.buttons.download-card')}
                </ULButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h5" style={{ fontWeight: 400, fontSize: '12px' }}>
                  {t('applicants-card.footer-description', {email : protectEmail(personalInfo.email ?? "")})}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ULButton variant="outlined" onClick={clearApplication}>
                  {t('buttons.exit')}
                </ULButton>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}