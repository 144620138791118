
import { Alert, Grid, Typography } from '@mui/material';
import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import * as APIServices from "../../services/rest/APIServices";
import { Check } from '@mui/icons-material';
import ApplicationContext from '../../context/ApplicationContext';
import { useContext } from 'react';
import ULButton from '../../components/ULButton';
import { useState } from 'react';

export default function SuccessfulPayment()
{
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { OMR } = useContext(ApplicationContext);
  const { applicationID } = useContext(ApplicationContext);
  const [errMessages, setErrMessages] = useState([]);
  const [working, setWorking] = useState(false);
  const [searchParams] = useSearchParams();
  const paid = searchParams.get('paid');

  const confirmProvidedInformation = (e) =>
  {
    setWorking(true);
    setErrMessages([]);
    APIServices.confirmProvidedInformation(applicationID)
      .then((response) =>
      {
        // Navegar a verificación de documentos
        // navigate(ROUTES.DOCUMENT_VERIFICATION)
      })
      .catch((error) =>
      {
        // setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
        // const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        // scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        // window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        navigate(ROUTES.DOCUMENT_VERIFICATION)
        setWorking(false);
      })
  }

  const dummyPayment = (e) =>
  {
    APIServices.dummyPayment(applicationID, paid === 'true')
      .then((response) =>
      {
      })
      .catch((error) =>
      {
        console.log(error);
      })
  }

  useState(() =>
  {
    dummyPayment();
  }, [])

  return (
    <>
      <Grid
        container
        spacing={2}
        item
      >
        {
          errMessages?.length ?
            <Grid item xs={12}>
              <Alert role="alert" severity="error" style={{ marginBottom: '1rem' }}>
                {
                  errMessages.map((err, index) => (
                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                  ))
                }
              </Alert>
            </Grid>
            : <></>
        }
        <Grid item xs={12}>
          <Check style={{ fontSize: '72px', margin: '0 auto', display: 'block', fill: '#2E7D32' }} />
          <Typography variant="h5" component="h3" style={{ marginBottom: '2rem', textAlign: 'center' }}>
            {t('payment-information.successful-payment')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ULButton
            onClick={confirmProvidedInformation}
            working={working}
            fullWidth
          >
            {t('buttons.continue')}
          </ULButton>
        </Grid>
        <Grid item xs={12}>
          <ULButton
            variant="outlined"
            goBack={true}
            onClick={() => navigate(ROUTES.PAYMENTS)}
            fullWidth
          >
            {t('buttons.back')}
          </ULButton>
        </Grid>
      </Grid>
    </>
  );
}