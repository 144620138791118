import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import TopBar from "../components/TopBar/TopBar";
import './Layout.css';
import Stepper from '../components/Stepper';
import { Card, Fab, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Popover, Typography } from '@mui/material';
import { Close, Email, Help, Phone, WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import * as APIServices from "../services/rest/APIServices";
import ApplicationContext from '../context/ApplicationContext';

const Layout = (props) =>
{
  const { title, optionBack, incident, showOMR, renderCount } = props;

  const [callName, setCallName] = useState(null);
  const [OMR, setOMR] = useState(null);
  const [value] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  const { applicationID } = useContext(ApplicationContext);


  const handleClickHelpOptions = (event) =>
  {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHelpOptions = () =>
  {
    setAnchorEl(null);
  };

  const openHelpOptions = Boolean(anchorEl);

  useEffect(() =>
  {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);


  const goBack = () =>
  {
    navigate(-1);
  }

  const callServices = async () =>
  {
    if (!applicationID) return;
    await APIServices.getHeaderData(applicationID).then((response) =>
    {
      const callName = response.data?.data?.call;
      const omr = response.data?.data?.omr;
      setCallName(callName);
      setOMR(omr);
    })
      .catch((error) =>
      {
        console.log(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
      })
  }

  useEffect(() =>
  {
    callServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() =>
  {
    callServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderCount])

  return (
    <Box ref={ref}>
      <TopBar
        optionBack={optionBack || (incident && location.state && location.state.location)}
        goBack={goBack}
        title={title}
      />
      {props.showStepper ? <Stepper step={props.step} updateStep={props.updateStep} callName={callName} /> : ''}
      {props.showImage ?
        <Grid item className="imageLayout">
          <img src={props.image} alt={t('layout.assistence-alt')} className={props.heigthCard ? "imageWelcomeWithoutTop" : "imageWelcome"} />
        </Grid>
        : <></>
      }
      <Card style={{ borderRadius: '.75rem', boxShadow: 'none' }} sx={{ margin: '16px', padding: '16px', marginTop: props.setMarginTop ? '1rem' : props.setMarginMinTop ? '2rem' : '16px', height: props.heigthCard ? '37.5rem !important' : '', top: props.heigthCard ? '14.5rem !important' : '' }}>
        {props.children}
        {
          showOMR && OMR ?
            <>
              <Grid item xs={12}>
                <Typography style={{ marginTop: '16px', textAlign: 'center' }}>
                  {t('common.omr-message', { omr: OMR })}
                </Typography>
              </Grid>
            </> : <></>
        }
      </Card>
      <CssBaseline />
      {!props.showImage ? <div>
        <Grid container justifyContent="center" alignItems="center" style={{ position: props.heigthCard ? '' : 'absolute', bottom: 0, width: '100%', height: '10px', marginTop: props.heigthCard ? '48rem' : '' }}>
          <Typography variant="h5" item="h5">
            {t('layout.app-name')} &bull; <span style={{ color: '#F37021' }}> {t('layout.ulima-abbr')} </span>
          </Typography>
        </Grid>
      </div> : <></>
      }

      <Popover
        open={openHelpOptions}
        anchorEl={anchorEl}
        onClose={handleCloseHelpOptions}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '16px', paddingTop: '0', background: '#ff5117', color: '#fff' }}>
          <List sx={{ width: '100%' }}>
            <ListItem
              key={value}
              disableGutters
              secondaryAction={
                <IconButton variant="text" onClick={handleCloseHelpOptions}>
                  <Close sx={{ color: 'white' }} />
                </IconButton>
              }
            >
              <ListItemText primary={
                <Typography variant="subtitle" component="h4">
                  {t('layout.need-help-q')}
                </Typography>
              } />
            </ListItem>
          </List>
          <Typography variant="body2" component="p">
            {t('layout.contact-options-title')}
          </Typography>
        </div>
        <List sx={{ my: 0 }}>
          <ListSubheader>{t('layout.media-email')}</ListSubheader>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='mailto:admision@ulima.edu.pe'>
              <ListItemButton>
                <ListItemIcon>
                  <Email />
                </ListItemIcon>
                <ListItemText primary={'admision@ulima.edu.pe'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListSubheader>{t('layout.media-whatsapp')}</ListSubheader>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='https://api.whatsapp.com/send?phone=51979945053&text=¡Hola! Necesito ayuda con mi postulación a la Universidad de Lima'>
              <ListItemButton>
                <ListItemIcon>
                  <WhatsApp />
                </ListItemIcon>
                <ListItemText primary={'+51 979 945 053'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListSubheader>{t('layout.media-phone')}</ListSubheader>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='tel:+51014376767,30475'>
              <ListItemButton>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={'(01) 437-6767, anexo 30475'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='tel:+51014376767,30476'>
              <ListItemButton>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={'(01) 437-6767, anexo 30476'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='tel:+51014376767,30477'>
              <ListItemButton>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={'(01) 437-6767, anexo 30477'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='tel:+51014376767,30478'>
              <ListItemButton>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={'(01) 437-6767, anexo 30478'} />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disableGutters sx={{ py: 0 }}>
            <Link target='_blank' style={{ width: '100%', color: 'inherit', textDecoration: 'none' }} to='tel:+51014376767,30479'>
              <ListItemButton>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary={'(01) 437-6767, anexo 30479'} />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
      </Popover>
      <Fab size="small" color="primary" style={{ position: 'sticky', bottom: '16px', left: 'calc(100% - 56px)' }} onClick={handleClickHelpOptions}>
        <Help />
      </Fab>
    </Box>
  );
};

Layout.defaultProps = {
  updateStep: true,
  showOMR: true,
}

export default Layout;