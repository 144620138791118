import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as ROUTES from "../routes.js";
import Welcome from "../../views/Common/Welcome.jsx";
import NewApplication from '../../views/Application/NewApplication.jsx';
import StudentIdentification from '../../views/StudentInformation/StudentIdentification.jsx';
import StudentPersonalData from '../../views/StudentInformation/StudentPersonalData.jsx';
import StudentChooseVerificationType from '../../views/Common/StudentChooseVerificationType.jsx';
import StudentCodeValidation from '../../views/Common/StudentCodeValidation.jsx';
import ChooseModality from '../../views/CareersAndModalities/ChooseModality.jsx';
import ChooseCareer from '../../views/CareersAndModalities/ChooseCareer.jsx';
import Layout from "../../Layout/Layout.jsx";

import EducationSchoolType from '../../views/Education/EducationSchoolType.jsx';
import EducationLocalSchoolData from '../../views/Education/EducationLocalSchoolData.jsx';
import EducationSchoolNotFound from '../../views/Education/EducationSchoolNotFound.jsx';

import EducationUniversityData from '../../views/CareersAndModalities/EducationUniversityData.jsx';
import EducationUniversityType from '../../views/CareersAndModalities/EducationUniversityType.jsx';
import EducationUniversityNotFound from '../../views/CareersAndModalities/EducationUniversityNotFound.jsx';
import Payment from '../../views/PaymentInformation/Payment.jsx';
import SuccessfulPayment from '../../views/PaymentInformation/SuccessfulPayment.jsx';
import ApplicantsCard from '../../views/ApplicantCard/ApplicantCard.jsx';

import ApplicationSuspend from '../../views/Common/ApplicationSuspend.jsx';
import ApplicationContinue from '../../views/Common/ApplicationContinue.jsx';
import MenuImage from "../../assets/images/cabecera_formulario_1200x400.jpg";
import RequestedDocuments from '../../views/Documents/RequestedDocuments.jsx';
import PictureVerificator from '../../views/Documents/PictureVerificator.jsx';
import ValidationResults from '../../views/Documents/ValidationResults.jsx';
import ApplicationSummary from '../../views/Common/ApplicationSummary.jsx';
import Disclaimers from '../../views/Disclaimers/Disclaimers.jsx';
import ApplicationRecoverOMR from '../../views/Common/ApplicationRecoverOMR.jsx';
import VerifiedDocuments from '../../views/DocumentVerification/VerifiedDocuments.jsx';
import BasicInfoCorrection from '../../views/DocumentVerification/BasicInfoCorrection.jsx';

const AppRouter = (props) =>
{
    const [renderCount, setRenderCount] = React.useState(0);

    const reRenderLayout = () =>
    {
        setRenderCount(prevCount => prevCount + 1);
    }

    let publicRoutes = (
        <Routes>
            <Route index element={<Welcome />}></Route>
            {/* Postulación */}
            <Route path={ROUTES.APPLICATION_NEW_APPLICANT} element={<Layout renderCount={renderCount} showOMR={false} showImage={true} updateStep={false} image={MenuImage}><NewApplication /></Layout>} />

            {/* Información del postulante */}
            <Route path={ROUTES.STUDENT_IDENTIFICATION} element={<Layout renderCount={renderCount} showOMR={false} setMarginTop={true} step={0} showStepper={true}> <StudentIdentification /></Layout>} />
            <Route path={ROUTES.STUDENT_PERSONAL_DATA} element={<Layout renderCount={renderCount} showOMR={false} setMarginTop={true} step={0} showStepper={true}><StudentPersonalData reRenderLayout={reRenderLayout} /></Layout>} />

            {/* Información de educación secundaria */}
            <Route path={ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}> <EducationSchoolType grade='fifth' reRenderLayout={reRenderLayout} /> </Layout>} />
            <Route path={ROUTES.EDUCATION_FOURTH_SCHOOL_TYPE} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}><EducationSchoolType grade='fourth' /></Layout>} />
            <Route path={ROUTES.EDUCATION_THIRD_SCHOOL_TYPE} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}><EducationSchoolType grade='third' /></Layout>} />

            <Route path={ROUTES.EDUCATION_FIFTH_LOCAL_SCHOOL} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}> <EducationLocalSchoolData grade='fifth' /> </Layout>} />
            <Route path={ROUTES.EDUCATION_FOURTH_LOCAL_SCHOOL} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}><EducationLocalSchoolData grade='fourth' /></Layout>} />
            <Route path={ROUTES.EDUCATION_THIRD_LOCAL_SCHOOL} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}><EducationLocalSchoolData grade='third' /></Layout>} />

            <Route path={ROUTES.EDUCATION_FIFTH_SCHOOL_NOT_FOUND} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}> <EducationSchoolNotFound grade="fifth" /> </Layout>} />
            <Route path={ROUTES.EDUCATION_FOURTH_SCHOOL_NOT_FOUND} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}> <EducationSchoolNotFound grade="fourth" /> </Layout>} />
            <Route path={ROUTES.EDUCATION_THIRD_SCHOOL_NOT_FOUND} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}> <EducationSchoolNotFound grade="third" /> </Layout>} />

            {/* Resumen de postulación */}
            <Route path={ROUTES.APPLICATION_SUMMARY} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={2} showStepper={true}> <ApplicationSummary /> </Layout>} />

            {/* Modalidades y carreras */}
            <Route path={ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={2} showStepper={true}><ChooseModality /></Layout>} />
            <Route path={ROUTES.MODALITY_AND_CARREERS_CHOOSE_CAREER} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={2} showStepper={true}><ChooseCareer /></Layout>} />

            {/* Información de educación universitaria */}
            <Route path={ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_TYPE} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={1} showStepper={true}> <EducationUniversityType /> </Layout>} />
            <Route path={ROUTES.MODALITY_AND_CARREERS_UNIVERSITY} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={2} showStepper={true}> <EducationUniversityData /> </Layout>} />
            <Route path={ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_FOREIGN} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={2} showStepper={true}> <EducationUniversityData local={false} /> </Layout>} />
            <Route path={ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_NOT_FOUND} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={2} showStepper={true}> <EducationUniversityNotFound /> </Layout>} />

            {/* Envío de documentos */}
            <Route path={ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={3} showStepper={true}><RequestedDocuments /></Layout>} />
            <Route path={ROUTES.DOCUMENTS_CARNE_PHOTO} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={3} showStepper={true}><PictureVerificator /></Layout>} />
            <Route path={ROUTES.DOCUMENTS_CARNE_PHOTO_VALIDATION_RESULTS} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={3} showStepper={true}><ValidationResults /></Layout>} />

            {/* Información de pagos */}
            <Route path={ROUTES.PAYMENTS} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={4} showStepper={true}><Payment /></Layout>} />
            <Route path={ROUTES.SUCCESSFUL_PAYMENT} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={4} showStepper={true}><SuccessfulPayment /></Layout>} />

            {/* Verificación de documentos */}
            <Route path={ROUTES.DOCUMENT_VERIFICATION} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={5} showStepper={true}><VerifiedDocuments /></Layout>} />
            <Route path={ROUTES.DOCUMENT_VERIFICATION_STUDENT_PERSONAL_DATA} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={5} showStepper={true}><BasicInfoCorrection /></Layout>} />

            {/* Carné del postulante */}
            <Route path={ROUTES.APPLICANTS_CARD} element={<Layout renderCount={renderCount} showOMR={true} setMarginTop={true} step={6} showStepper={true}><ApplicantsCard /></Layout>} />

            {/* Comunes */}
            <Route path={ROUTES.APPLICATION_SUSPEND} element={<Layout renderCount={renderCount} showOMR={false} updateStep={false}><ApplicationSuspend /></Layout>} />
            <Route path={ROUTES.APPLICATION_CONTINUE} element={<Layout renderCount={renderCount} showOMR={false} updateStep={false}><ApplicationContinue /></Layout>} />

            <Route path={ROUTES.STUDENT_CHOOSE_VERIFICATION_TYPE} element={<Layout renderCount={renderCount} showOMR={false} setMarginTop={true} step={0} showStepper={true} updateStep={false}><StudentChooseVerificationType /></Layout>} />
            <Route path={ROUTES.STUDENT_CODE_VALIDATION} element={<Layout renderCount={renderCount} showOMR={false} setMarginTop={true} step={0} showStepper={true} updateStep={false}><StudentCodeValidation  reRenderLayout={reRenderLayout} /></Layout>} />
            <Route path={ROUTES.APPLICATION_RECOVER_OMR} element={<Layout renderCount={renderCount} showOMR={false} setMarginTop={true} step={0} showStepper={false}> <ApplicationRecoverOMR /> </Layout>} />

            {/* Disclaimers */}
            <Route path={ROUTES.DISCLAIMERS} element={<Disclaimers />} />
        </Routes>
    )

    return (
        <BrowserRouter>
            {publicRoutes}
        </BrowserRouter>
    )
};

export default AppRouter;