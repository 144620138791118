import * as React from 'react';
import { Alert, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import { useContext } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from '../../context/ApplicationContext';
import { useEffect } from 'react';
import Loading from '../../components/Loading';
import PersonalInformationContext from '../../context/PersonalInformationContext';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';
import { handlePreventTypingChars } from '../../utils/Validations';

const BasicInfoCorrection = (props) =>
{
  const { applicationID, correcting, setCallID } = useContext(ApplicationContext);
  const { name, setName, firstLastName, setFirstLastName, secondLastName, setSecondLastName, documentNumber, setDocumentNumber, documentType, setDocumentType } = useContext(PersonalInformationContext);
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [condition, setCondition] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessages, setErrMessages] = useState([]);

  const [dtype, setDtype] = useState(-1);
  const [showInputID, setShowInputID] = useState(false);
  const [showInputDni, setShowInputDni] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const location = useLocation();
  const applicationDocumentId = location.state?.applicationDocumentId ?? 0;
  console.log(applicationDocumentId)

  const evaluateStudent = (e) =>
  {
    const data = {
      "applicationId": applicationID,
      "name": name,
      "firstSurname": firstLastName,
      "secondSurname": secondLastName,
      "applicationDocumentId": applicationDocumentId,
      "documentTypeId": documentType,
      "documentNumber": documentNumber,
    }
    setWorking(true);
    setErrMessages([]);
    APIServices.updateApplicantInfo(data)
      .then((response) =>
      {
        navigate(ROUTES.DOCUMENT_VERIFICATION);
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setWorking(false);
      })
  }

  const handleChangeName = (e) =>
  {
    setName(e.target.value.toUpperCase())
  }

  const handleChangeFirstLastName = (e) =>
  {
    setFirstLastName(e.target.value.toUpperCase())
  }

  const handleChangeSecondLastName = (e) =>
  {
    setSecondLastName(e.target.value.toUpperCase())
  }

  const conditionPersonalInfo = () =>
  {
    const result = name?.length > 0 && firstLastName?.length > 0 && secondLastName?.length > 0;
    return result;
  }

  function conditionIdentification()
  {
    if (dtype === 100) return documentType > 0 && documentNumber.length > 0 && documentNumber.length < 20;
    return documentNumber.length === 8;
  }

  useEffect(() =>
  {
    setCondition(conditionPersonalInfo() && conditionIdentification())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, firstLastName, secondLastName, documentType, documentNumber, dtype]);


  function showInputText(choice)
  {
    setDtype(parseInt(choice));
    if (parseInt(choice) === 100)
    {
      setShowInputDni(false);
      setShowInputID(true);
      setDocumentType(0);
    }
    else
    {
      setShowInputDni(true);
      setShowInputID(false);
      setDocumentType(parseInt(choice));
    };
  }

  const handleChangeDocumentType = (e) =>
  {
    setDocumentType(parseInt(e.target.value));
  }

  useEffect(() =>
  {
    const callServices = async () =>
    {
      setIsLoading(true);
      await APIServices.getDocumentTypeList().then((response) =>
      {
        setDocumentTypeList(response.data.data?.list ?? []);
      })
        .catch((error) =>
        {
          setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
          const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
          scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() =>
        {
          setIsLoading(false);
        })
    }
    callServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () =>
  {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchApplicantInfoReview(applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        if (data)
        {
          setDocumentNumber(data.documentNumber);
          showInputText(data.isMain ? data.documentTypeId : 100);
          setDocumentType(data.documentTypeId);
          setCallID(data.callId ?? 0);
          setName(data.name ?? '');
          setFirstLastName(data.firstSurname ?? '');
          setSecondLastName(data.secondSurname ?? '');
        }
        // const status = response.data.data?.status;
        // setCanEdit(canUserEditInformation(status));
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  useEffect(() =>
  {
    fetchData();
  }, [])

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ? <Grid item xs={12}>
                  <Alert role="alert" severity="error">
                    {
                      errMessages.map((err, index) => (
                        <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                      ))
                    }
                  </Alert>
                </Grid> : <></>
              }

              <Grid item xs={12}>
                <Typography variant="h5" component="h4">
                  {t('personal-data.personal-data-correct')}
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h5">
                    {t('identification.identification')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <RadioGroup
                      value={dtype}
                    >
                      {
                        documentTypeList?.map((documentType, index) => (
                          documentType.isMain && <FormControlLabel disabled={!canEdit} onChange={(e) => { showInputText(e.target.value); }} key={index} className="radio-button" value={documentType.documentTypeId} control={<Radio />} label={documentType.name} />
                        ))
                      }
                      <FormControlLabel disabled={!canEdit} onChange={(e) => { showInputText(e.target.value); }} className="radio-button" value="100" control={<Radio />} label={t('identification.document-type-option-other')} />

                    </RadioGroup>
                  </FormControl>
                </Grid>
                {showInputDni &&
                  <>
                    <Grid item xs={12}>
                      <TextField disabled={!canEdit} inputProps={{ maxLength: 8 }} onChange={(e) => setDocumentNumber(e.target.value)} onKeyDown={handlePreventTypingChars} value={documentNumber} fullWidth label={t('identification.document-number-placeholder')} />
                    </Grid>
                  </>
                }

                {showInputID &&
                  <Grid item xs={12}>

                    <FormControl fullWidth>
                      <InputLabel id="document-type-label">{t('identification.document-type-placeholder')}</InputLabel>
                      <Select
                        value={documentType === 0 ? undefined : documentType}
                        onChange={handleChangeDocumentType}
                        disabled={!canEdit}
                      >
                        {
                          documentTypeList?.map((documentType, index) => (
                            !documentType.isMain && <MenuItem key={index} value={documentType.documentTypeId}>{documentType.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                }

                {showInputID &&
                  <Grid item xs={12}>
                    <TextField disabled={!canEdit} onChange={(e) => setDocumentNumber(e.target.value)} onKeyDown={handlePreventTypingChars} value={documentNumber} fullWidth label={t('identification.document-number-placeholder')} />
                  </Grid>
                }
                <Grid item xs={12}>
                  <Typography variant="h5" component="h5">
                    {t('personal-data.personal-data')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeName(e)} value={name} fullWidth label={t('personal-data.names-placeholder')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeFirstLastName(e)} value={firstLastName} fullWidth label={t('personal-data.first-lastname')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={!canEdit} autoComplete='off' onChange={(e) => handleChangeSecondLastName(e)} value={secondLastName} fullWidth label={t('personal-data.second-lastname')} />
                </Grid>
                <Grid item xs={12} >
                  <ULButton working={working} onClick={evaluateStudent} disabled={!condition}>{t('buttons.continue')}</ULButton>
                </Grid>
                <Grid item xs={12} >
                  <ULButton variant="outlined" onClick={() => navigate(ROUTES.DOCUMENT_VERIFICATION)} goBack={true}>{t('buttons.back')}</ULButton>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  )
}

export default BasicInfoCorrection;