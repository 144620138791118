import { Alert, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import * as APIServices from "../../services/rest/APIServices";
import { useContext, useEffect } from 'react';
import ApplicationContext from '../../context/ApplicationContext';
import ULButton from '../../components/ULButton';
import { useState } from 'react';
import Loading from '../../components/Loading';
import { CheckCircleOutline } from '@mui/icons-material';
import { SCROLLABLE_AREA_NAME } from '../../utils/CommonFunctions';

const baseURL = process.env.REACT_APP_API_ENDPOINT;


export default function Payment()
{
  const username = process.env.REACT_APP_NIUBIZ_USERNAME;
  const password = process.env.REACT_APP_NIUBIZ_PASSWORD;
  const accessUrl = process.env.REACT_APP_NIUBIZ_API_ACCESS_TOKEN;
  const sessionUrl = process.env.REACT_APP_NIUBIZ_API_SESSION_TOKEN;

  const [errMessages, setErrMessages] = useState([]);
  const [payment, setPayment] = useState(0);
  const [lastDayToPay, setLastDayToPay] = useState('');
  const [sessionKey, setSessionKey] = useState('');
  const { OMR } = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { applicationID } = useContext(ApplicationContext);
  const [isPaid, setIsPaid] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const fetchPaymentInfo = () =>
  {
    setIsLoading(true);
    APIServices.fetchPaymentInfo(applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        setPayment(data.paymentAmount ?? 0);
        setLastDayToPay(data.lastPaymentDate);
      })
      .catch((error) =>
      {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() =>
      {
        setIsLoading(false);
      })
  }

  const checkPayment = () =>
  {
    APIServices.listFilesDC(applicationID)
      .then((response) =>
      {
        const data = response.data.data;
        // Provisional, bloquea el botón de pago tras pagar. Cambiará con la integración de Niubiz defintiva.
        setIsPaid(data.status === 2 ?? false);
      })
      .catch((error) =>
      {
      })
      .finally(() =>
      {
      })
  }

  useEffect(() =>
  {
    checkPayment();
    fetchPaymentInfo();
  }, [])

  useEffect(() =>
  {
    if (payment === 0) return;

    const script = document.createElement('script');
    script.src = "https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true";
    script.async = true;
    document.body.appendChild(script);
    axios.get(accessUrl, {
      headers: {
        Authorization: btoa(username + ":" + password)
      }
    })
      .then((response) =>
      {
        axios.post(sessionUrl, {
          "channel": "web",
          "amount": payment,
          "antifraud": {
            "merchantDefineData": {
              "MDD15": "Valor MDD 15",
              "MDD20": "Valor MDD 20",
              "MDD33": "Valor MDD 33"
            }
          }
        }, {
          headers: {
            Authorization: response.data,
          }
        })
          .then((response) =>
          {
            setSessionKey(response.data.sessionKey);
          })
      })

    return () =>
    {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment])

  const openForm = () =>
  {
    /* eslint-disable no-undef */
    VisanetCheckout.configure({
      sessiontoken: sessionKey,
      channel: 'web',
      merchantid: '456879852',
      purchasenumber: 2020100901,
      amount: payment,
      expirationminutes: '20',
      timeouturl: 'about:blank',
      formbuttoncolor: '#FF5117',
      action: `${baseURL}/api/v1/payment/redirect?callback=${window.location.origin}${ROUTES.SUCCESSFUL_PAYMENT}?paid=true`,
      complete: function (params)
      {
        console.log(JSON.stringify(params));
      }
    });
    VisanetCheckout.open();
    /* eslint-enable no-undef */
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid
              style={{ marginBottom: '16px' }}
              item
              xs={12}
              container
              spacing={2}
            >
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              {
                isPaid ?
                  <>
                    <Grid item xs={12}>
                      <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
                        {t('payment-information.already-paid')}
                      </Alert>
                    </Grid>
                  </> : <></>
              }
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={isPaid ? t('payment-information.amount-paid') : t('payment-information.amount-to-pay')}
                  value={payment}
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={isPaid ? t('payment-information.date-paid') : t('payment-information.last-date-to-pay')}
                  value={lastDayToPay}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              item
            >
              <Grid
                item
                container
                xs={12}
                spacing={1}
              >
                {
                  !isPaid ?
                    <>
                      <Grid item xs={12}>
                        <ULButton
                          variant="contained"
                          fullWidth
                          onClick={openForm}
                        >
                          {t('payment-information.buttons.pay', { currency_symbol: 'S/', ammount: payment })}
                        </ULButton>
                      </Grid>
                    </> : <>
                      <Grid item xs={12}>
                        <ULButton
                          variant="contained"
                          fullWidth
                          onClick={() => { navigate(ROUTES.DOCUMENT_VERIFICATION) }}
                        >
                          {t('payment-information.buttons.continue')}
                        </ULButton>
                      </Grid>
                    </>
                }
                <Grid item xs={12}>
                  <ULButton
                    variant="outlined"
                    onClick={() => { navigate(ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS) }}
                    fullWidth
                    goBack={true}
                  >
                    {t('buttons.back')}
                  </ULButton>
                </Grid>
                {
                  !isPaid ?
                    <>
                      <Grid item xs={12}>
                        <ULButton
                          variant="outlined"
                          color="secondary"
                          onClick={() => { navigate(ROUTES.APPLICATION_SUSPEND, { state: { step: 4 } }) }}
                          fullWidth
                        >
                          {t('payment-information.buttons.pay-later')}
                        </ULButton>
                      </Grid>
                    </> : <></>
                }
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}