import * as React from 'react';
import { Grid, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import { useTranslation } from "react-i18next";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { handlePreventTypingChars } from '../../utils/Validations';

const StudentPersonalDataDisability = (props) =>
{

  const { t } = useTranslation();

  const handleHasConadisChange = (value) =>
  {
    props.handleChangeHasConadis(value);
  };

  const handleDisabilityDescriptionChange = (value) =>
  {
    props.handleChangeDisabilityDescription(value);
  };

  const handleConadisDocumentChange = (value) =>
  {
    props.handleChangeConadisDocument(value);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <TextField disabled={!props.canEdit} onChange={(e) => handleDisabilityDescriptionChange(e.target.value)} value={props.disabilityDescription} fullWidth label={t('personal-data.personal-data-disability.description-placeholder')} />
      </Grid>
      <Grid item xs={12}>
      <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
          {t('personal-data.personal-data-disability.title')}
        </Typography>
        <FormControl fullWidth>
          <RadioGroup
            value={props.hasConadis}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControlLabel
                  value="si"
                  disabled={!props.canEdit}
                  control={<Radio checked={props.hasConadis === 'si'} />}
                  label={t('education.fifth-year.fifth-year-information.same-institute-options.yes')}
                  onChange={(e) => handleHasConadisChange(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  value="no"
                  disabled={!props.canEdit}
                  control={<Radio checked={props.hasConadis === 'no'} />}
                  label={t('education.fifth-year.fifth-year-information.same-institute-options.no')}
                  onChange={(e) => handleHasConadisChange(e.target.value)}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* {
        props.hasConadis === "si" ?
          <>
            <Grid item xs={12}>
              <TextField disabled={!props.canEdit} autoComplete='off' onKeyDown={handlePreventTypingChars} onChange={(e) => handleConadisDocumentChange(e.target.value)} value={props.conadisDocument} fullWidth label={t('personal-data.personal-data-disability.document-number')} />
            </Grid>
          </> : <></>
      } */}
    </Grid >
  )
}

export default StudentPersonalDataDisability;