import { Alert, Autocomplete, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { useContext } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes/routes";
import Loading from '../../components/Loading';
import ApplicationContext from '../../context/ApplicationContext';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import axios from 'axios';
import ULButton from '../../components/ULButton';
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from '../../utils/CommonFunctions';
import { MIN_DATE, handlePreventTypingChars } from '../../utils/Validations';
import ModalityAndCareerContext from '../../context/ModalityAndCareerContext';

const EducationUniversityData = (props) => {
  const { applicationID, correcting } = useContext(ApplicationContext);
  const currentYear = new Date().getFullYear();
  const [errMessages, setErrMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { universityData, setUniversityData } = useContext(ModalityAndCareerContext);
  const [university, setUniversity] = React.useState();
  const [openUniversityList, setOpenUniversityList] = React.useState(false);
  const [universityList, setUniversityList] = React.useState([]);
  const [loadingSearchResultsUniversities, setLoadingSearchResultsUniversities] = React.useState(false);
  const [searchKeyUniversity, setSearchKeyUniversity] = React.useState('');

  const [universityTypeId, setUniversityTypeId] = React.useState(0);
  const [geolocation, setGeolocation] = React.useState();
  const [openGeolocationList, setOpenGeolocationList] = React.useState(false);
  const [geolocationList, setGeolocationList] = React.useState([]);
  const [loadingSearchResultsGeolocation, setLoadingSearchResultsGeolocation] = React.useState(false);
  const [searchKeyGeolocation, setSearchKeyGeolocation] = React.useState('');
  const [working, setWorking] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  const [errFirstPeriodCycle, setErrFirstPeriodCycle] = useState('');
  const [errFirstPeriodYear, setErrFirstPeriodYear] = useState('');
  const [errLastPeriodCycle, setErrLastPeriodCycle] = useState('');
  const [errLastPeriodYear, setErrLastPeriodYear] = useState('');

  const checkValidFirstPeriodCycle = (value) => {
    if (value !== 1 && value !== 2) {
      setErrFirstPeriodCycle(t('modalities-and-careers.university.err-invalid-first-period-cycle'));
    }
    else {
      setErrFirstPeriodCycle('');
    }
  }

  const handleFirstPeriodCycleChange = (e) => {
    setUniversityData({ ...universityData, firstPeriodCycle: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0 });
    checkValidFirstPeriodCycle(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)
  }

  const checkValidFirstPeriodYear = (value, ref = undefined) => {
    const v_str = value.toString() ?? '';
    const cmp = ref ?? universityData.lastPeriodYear;
    if (v_str.length === 4 && (cmp || value < MIN_DATE || value > currentYear) && (value >= cmp || value >= currentYear || value < MIN_DATE)) {
      setErrFirstPeriodYear(t('modalities-and-careers.university.err-invalid-first-period-year', { date: MIN_DATE }));
    }
    else {
      setErrFirstPeriodYear('');
    }
  }

  const handleFirstPeriodYearChange = (e) => {
    setUniversityData({ ...universityData, firstPeriodYear: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0 });
    checkValidFirstPeriodYear(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0);
  }

  const checkValidLastPeriodCycle = (value) => {
    if (value !== 1 && value !== 2) {
      setErrLastPeriodCycle(t('modalities-and-careers.university.err-invalid-last-period-cycle'));
    }
    else {
      setErrLastPeriodCycle('');
    }
  }

  const handleLastPeriodCycleChange = (e) => {
    setUniversityData({ ...universityData, lastPeriodCycle: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0 });
    checkValidLastPeriodCycle(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)
  }

  const checkValidLastPeriodYear = (value) => {
    const v_str = value.toString() ?? '';
    if (v_str.length === 4 && (value > currentYear || value < MIN_DATE)) {
      setErrLastPeriodYear(t('modalities-and-careers.university.err-invalid-last-period-year', { date: MIN_DATE }));
    }
    else {
      setErrLastPeriodYear('');
    }
    checkValidFirstPeriodYear(universityData.firstPeriodYear, value);
  }

  const handleLastPeriodYearChange = (e) => {
    setUniversityData({ ...universityData, lastPeriodYear: !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0 });
    checkValidLastPeriodYear(!isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : 0)
  }

  const navigate = useNavigate();

  const { t } = useTranslation();


  /* Geolocation */
  const handleSearchGeolocation = (e) => {
    setSearchKeyGeolocation(e.target.value);
  };

  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const callServices = async () => {
      setLoadingSearchResultsGeolocation(true);
      await APIServices.getGeolocationInternationalAutocompleteList(props.local, searchKeyGeolocation, cancelTokenSource).then((response) => {
        if (response.data.data?.list?.length) {
          const results = response.data.data.list;
          setGeolocationList(results);
        }
        else {
          setGeolocationList([]);
        }
      })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
            // const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
            // scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
            // window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        })
        .finally(() => {
          setLoadingSearchResultsGeolocation(false);
        })
    }

    if (searchKeyGeolocation?.length >= 3) {
      callServices();
    } else {
      setGeolocationList([]);
      setLoadingSearchResultsGeolocation(false);
    }
    return () => cancelTokenSource.cancel(t('common.autocomplete.aborted'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyGeolocation]);

  const handleGeolocationChange = (event, value) => {
    setGeolocation(value);
  };

  React.useEffect(() => {
    setUniversityData({
      ...universityData,
      geolocation: geolocation ? geolocation.geolocation : '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation]);

  /* University */
  const handleSearchUniversity = (e) => {
    setSearchKeyUniversity(e.target.value);
  };

  React.useEffect(() =>
  {
    const callServices = async () =>
    {
      setIsLoading(true);
      await APIServices.getUniversityTypeList().then((response) =>
      {
        const universityTypesList = response.data.data?.list ?? [];
        let uType = universityTypesList.find(item => item.isMain)?.universityType ?? 0;
        if (!props.local)
        {
          uType = universityTypesList.find(item => !item.isMain)?.universityType ?? 0;
        }
        setUniversityTypeId (uType);
      })
        .catch((error) =>
        {
          setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
          const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
          scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .finally(() =>
        {
          setIsLoading(false);
        })
    }
    callServices();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();

    const callServices = async () => {
      setLoadingSearchResultsUniversities(true);
      await APIServices.getUniversityAutocompleteList(props.local, universityTypeId, searchKeyUniversity, geolocation?.geolocation ?? '', cancelTokenSource).then((response) => {
        if (response.data.data?.list?.length) {
          const results = response.data.data.list;
          setUniversityList(results);
        }
        else {
          setUniversityList([]);
        }
      })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            // setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
            // const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
            // scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
            // window.scrollTo({ top: 0, behavior: 'smooth' });
          }
        })
        .finally(() => {
          setLoadingSearchResultsUniversities(false);
        })
    }

    if (searchKeyUniversity?.length >= 3) {
      callServices();
    } else {
      setUniversityList([]);
      setLoadingSearchResultsUniversities(false);
    }
    return () => cancelTokenSource.cancel(t('common.autocomplete.aborted'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyUniversity]);

  const handleUniversityChange = (event, value) => {
    setUniversity(value);
    // setUniversityList([]);
  };

  React.useEffect(() => {
    setUniversityData({
      ...universityData,
      universityId: university ? university.universityId : 0,
    })
    setGeolocation({ geolocation: university?.geolocation ?? '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [university]);

  const handleChangeUniversityNotFound = () => {
    const notFound = universityData.notFound;
    setUniversityData({
      ...universityData,
      universityId: 0,
      notFound: !notFound,
    })
    setUniversityList([]);
    setGeolocationList([]);
  };

  const transformFetchData = (data) => {
    const resData = {
      universityId: data?.universityId ?? 0,
      universityType: data?.universityType ?? 0,
      firstPeriodYear: data?.firstPeriodYear ?? 0,
      firstPeriodCycle: data?.firstPeriodCycle ?? 0,
      lastPeriodYear: data?.lastPeriodYear ?? 0,
      lastPeriodCycle: data?.lastPeriodCycle ?? 0,
      notFound: data?.notFound ?? false,
      geolocation: data?.geolocation ?? '',
    }
    return resData;
  }

  const fetchData = async () => {
    if (!applicationID) return;
    setIsLoading(true);
    await APIServices.fetchModalityAndCareerInfo(applicationID)
      .then((response) => {
        const data = response.data.data;
        const universityTData = transformFetchData(data?.university);
        setUniversityData(universityTData);
        setSearchKeyUniversity(data.university?.name ?? '');
        setUniversity(data.university?.universityId ? data.university : null);
        setSearchKeyGeolocation(universityTData.geolocation);
        setGeolocation({ geolocation: universityTData.geolocation });
        const status = response.data.data?.status;
        setCanEdit(canUserEditInformation(status));
      })
      .catch((error) => {
        // No se pudo obtener la info (aún no ha sido registrada)
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformSaveData = (d) => {
    const data = {
      applicationId: applicationID,
      university: {
        universityId: d?.universityId ?? 0,
        universityType: d?.universityType ?? 0,
        firstPeriodYear: d?.firstPeriodYear ?? 0,
        firstPeriodCycle: d?.firstPeriodCycle ?? 0,
        lastPeriodYear: d?.lastPeriodYear ?? 0,
        lastPeriodCycle: d?.lastPeriodCycle ?? 0,
        notFound: d?.notFound ?? false,
        geolocation: d?.geolocation ?? '',
      }
    }
    return data;
  }

  const saveEducationInfo = (e) => {
    const data = transformSaveData(universityData);
    if (data.university.universityId === 0)
      delete data.university.universityId
    setWorking(true);
    setErrMessages([]);

    APIServices.saveModalityAndCareerInfo(data)
      .then((response) => {
        navigateTo();
      })
      .catch((error) => {
        setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
        const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
        scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .finally(() => {
        setWorking(false);
      })
  }

  const navigateTo = () => {
    if (universityData.notFound) {
      navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_NOT_FOUND);
    }
    else {
      navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_CAREER);
    }
  }

  const handleContinue = (e) => {
    if (!canEdit) {
      navigateTo();
    }
    else {
      saveEducationInfo(e);
    }
  }

  return (
    <>
      {
        isLoading ?
          <>
            <Loading />
          </> :
          <>
            <Grid container direction="column" spacing={2}>
              {
                errMessages?.length ?
                  <Grid item xs={12}>
                    <Alert role="alert" severity="error">
                      {
                        errMessages.map((err, index) => (
                          <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                        ))
                      }
                    </Alert>
                  </Grid>
                  : <></>
              }
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                    {t('modalities-and-careers.university.title')}</Typography>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Stack>
                      <Autocomplete
                        disabled={!canEdit || universityData.notFound}
                        noOptionsText={t('common.autocomplete.no-options')}
                        clearText={t('common.autocomplete.clear')}
                        loadingText={t('common.autocomplete.loading')}
                        freeSolo
                        open={openGeolocationList}
                        onOpen={() => {
                          setOpenGeolocationList(true);
                        }}
                        onClose={() => {
                          setOpenGeolocationList(false);
                        }}
                        getOptionLabel={(option) => option.geolocation}
                        options={geolocationList}
                        value={geolocation}
                        renderOption={(props, option, { inputValue }) => {
                          const matches = match(option.geolocation, inputValue, { insideWords: true });
                          const parts = parse(option.geolocation, matches);
                          props.key = props.id;
                          return (
                            <div key={option.geolocation} style={{ display: 'block' }} {...props}>
                              <p style={{ margin: '0' }}>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </p>
                            </div>
                          )
                        }
                        }
                        // filterOptions={filterOptions}
                        loading={loadingSearchResultsGeolocation}
                        onChange={handleGeolocationChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={props.local ? t('modalities-and-careers.university.geolocation-placeholder-local') : t('modalities-and-careers.university.geolocation-placeholder-foreign')}
                            label={t('modalities-and-careers.university.geolocation-label')}
                            value={searchKeyGeolocation ?? ''}
                            onChange={handleSearchGeolocation}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingSearchResultsGeolocation ?
                                    <CircularProgress color="inherit" size={20} /> :
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Autocomplete
                        disabled={!canEdit || universityData.notFound}
                        noOptionsText={t('common.autocomplete.no-options')}
                        clearText={t('common.autocomplete.clear')}
                        loadingText={t('common.autocomplete.loading')}
                        freeSolo
                        open={openUniversityList}
                        onOpen={() => {
                          setOpenUniversityList(true);
                        }}
                        onClose={() => {
                          setOpenUniversityList(false);
                        }}
                        // onBlur={() =>
                        // {
                        //   setUniversityList([])
                        // }}
                        getOptionLabel={(option) => option.name}
                        options={universityList}
                        value={university}
                        renderOption={(props, option, { inputValue }) => {
                          const matches = match(option.name, inputValue, { insideWords: true });
                          const parts = parse(option.name, matches);
                          props.key = props.id;
                          return (
                            <div key={option.universityId} style={{ display: 'block' }} {...props}>
                              <p style={{ margin: '0' }}>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </p>
                              <p style={{ margin: '0' }}><small>{option.address}</small></p>
                            </div>
                          )
                        }
                        }
                        // filterOptions={filterOptions}
                        loading={loadingSearchResultsUniversities}
                        onChange={handleUniversityChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('modalities-and-careers.university.university-name')}
                            value={searchKeyUniversity}
                            onChange={handleSearchUniversity}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loadingSearchResultsUniversities ?
                                    <CircularProgress color="inherit" size={20} /> :
                                    <InputAdornment position="end">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  {
                    university && (
                      <Grid item xs={12}>
                        <Typography variant="body2" component="p"><b>{t('education.common.address')} {university.address}</b></Typography>
                      </Grid>
                    )
                  }
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox onChange={handleChangeUniversityNotFound} disabled={!canEdit} checked={universityData.notFound} inputProps={{ 'aria-label': 'controlled' }} />}
                      label={t('modalities-and-careers.university.university-not-found')}
                    />
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography>{t('modalities-and-careers.university.first-period-title')}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl fullWidth>
                      <TextField inputProps={{ maxLength: 4 }}
                        disabled={!canEdit}
                        onKeyDown={handlePreventTypingChars}
                        fullWidth
                        value={universityData.firstPeriodYear ? universityData.firstPeriodYear : ''}
                        label={t('modalities-and-careers.university.first-period-year')}
                        onChange={handleFirstPeriodYearChange}
                      />
                    </FormControl>
                    {
                      errFirstPeriodYear.length ?
                        <>
                          <Grid item>
                            <div className="disclaimer validation-error">{errFirstPeriodYear}</div>
                          </Grid>
                        </> : <></>
                    }
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl fullWidth>
                      <TextField inputProps={{ maxLength: 1 }}
                        disabled={!canEdit}
                        onKeyDown={handlePreventTypingChars}
                        fullWidth
                        value={universityData.firstPeriodCycle ? universityData.firstPeriodCycle : ''}
                        label={t('modalities-and-careers.university.first-period-cycle')}
                        onChange={handleFirstPeriodCycleChange}
                      />
                    </FormControl>
                    {
                      errFirstPeriodCycle.length ?
                        <>
                          <Grid item>
                            <div className="disclaimer validation-error">{errFirstPeriodCycle}</div>
                          </Grid>
                        </> : <></>
                    }
                  </Grid>
                </Grid>

                <Grid item container spacing={2}>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography>{t('modalities-and-careers.university.last-period-title')}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl fullWidth>
                      <TextField inputProps={{ maxLength: 4 }}
                        disabled={!canEdit}
                        onKeyDown={handlePreventTypingChars}
                        fullWidth
                        value={universityData.lastPeriodYear ? universityData.lastPeriodYear : ''}
                        label={t('modalities-and-careers.university.last-period-year')}
                        onChange={handleLastPeriodYearChange}
                      />
                    </FormControl>
                    {
                      errLastPeriodYear.length ?
                        <>
                          <Grid item>
                            <div className="disclaimer validation-error">{errLastPeriodYear}</div>
                          </Grid>
                        </> : <></>
                    }
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <FormControl fullWidth>
                      <TextField inputProps={{ maxLength: 1 }}
                        disabled={!canEdit}
                        onKeyDown={handlePreventTypingChars}
                        fullWidth
                        value={universityData.lastPeriodCycle ? universityData.lastPeriodCycle : ''}
                        label={t('modalities-and-careers.university.last-period-cycle')}
                        onChange={handleLastPeriodCycleChange}
                      />
                    </FormControl>
                    {
                      errLastPeriodCycle.length ?
                        <>
                          <Grid item>
                            <div className="disclaimer validation-error">{errLastPeriodCycle}</div>
                          </Grid>
                        </> : <></>
                    }
                  </Grid>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <ULButton
                      working={working}
                      onClick={handleContinue}
                      disabled={
                        universityData.firstPeriodYear >= universityData.lastPeriodYear || universityData.firstPeriodYear < MIN_DATE || universityData.firstPeriodYear > currentYear ||
                        universityData.lastPeriodYear > currentYear || universityData.lastPeriodYear < MIN_DATE ||
                        !(universityData.firstPeriodCycle === 1 || universityData.firstPeriodCycle === 2) ||
                        !(universityData.lastPeriodCycle === 1 || universityData.lastPeriodCycle === 2) ||
                        (!universityData.notFound && universityData.universityId <= 0)
                      }
                    >
                      {t('buttons.continue')}
                    </ULButton>
                  </Grid>
                  <Grid item xs={12}
                  >
                    <ULButton
                      variant="outlined"
                      onClick={() => { navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_TYPE) }}
                      goBack={true}
                    >
                      {t('buttons.back')}
                    </ULButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </>
  );
}


EducationUniversityData.defaultProps = {
  local: true,
}

export default EducationUniversityData; 