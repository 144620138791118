import { useTranslation } from "react-i18next";
import './Stepper.css';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import * as ROUTES from "../../routes/routes";
import { useContext } from 'react';
import ApplicationContext from '../../context/ApplicationContext';
import { useEffect } from 'react';
import * as APIServices from "../../services/rest/APIServices";
import { getCurrentStep, setCurrentStep } from '../../utils/CommonFunctions';
import { Fragment } from 'react';

const StepperComponent = (props) =>
{
  const { t } = useTranslation();
  const { step, updateStep, callName } = props;
  let navigate = useNavigate();
  const { applicationID } = useContext(ApplicationContext);

  const steps = [
    {
      'name': t('stepper.applicant-information'),
      'link': ROUTES.STUDENT_IDENTIFICATION
    },
    {
      'name': t('stepper.school-information'),
      'link': ROUTES.EDUCATION_FIFTH_SCHOOL_TYPE
    },
    {
      'name': t('stepper.careers'),
      'link': ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY
    },
    {
      'name': t('stepper.documents'),
      'link': ROUTES.DOCUMENTS_REQUESTED_DOCUMENTS
    },
    {
      'name': t('stepper.payment'),
      'link': ROUTES.PAYMENTS
    },
    {
      'name': t('stepper.verification'),
      'link': ROUTES.DOCUMENT_VERIFICATION
    },
    {
      'name': t('stepper.applicant-card'),
      'link': ROUTES.APPLICANTS_CARD
    },
  ]

  function goToPage(index)
  {
    navigate(steps[index].link)
  }

  useEffect(() =>
  {
    if (applicationID)
    {
      if (step > 0 && step > getCurrentStep() && updateStep)
      {
        setCurrentStep(step);
        APIServices.updateStep(applicationID, step + 1)
          .then(() => { })
          .catch((error) => { console.log(`Step ${step + 1}: `, error) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, applicationID]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" className='stepper-grid'>
        <div className="stepper-container">
          {
            steps.map((step, index) => (
              <Fragment key={index}>
                {
                  index === props.step ?
                    <>
                      <div className='step-container'>
                        <div className='step-indicator step-current'>
                          <span>{index + 1}</span>
                        </div>
                      </div>
                    </> : index < step ?
                      <>
                        <div className='step-container'>
                          <div key={index} className='step-indicator step-completed' onClick={() => goToPage(index)}>
                            <span>{index + 1}</span>
                          </div>
                        </div>
                      </> :
                      <>
                        <div className='step-container'>
                          <div key={index} className={`step-indicator ${index - 1 < getCurrentStep() ? "step-prev-completed" : ""}`} onClick={() => { index - 1 < getCurrentStep() && goToPage(index) }}>
                            <span>{index + 1}</span>
                          </div>
                        </div>
                      </>
                }
              </Fragment>
            ))
          }
        </div>
        <h5 style={{ fontWeight: 700, fontSize: '1rem', margin: '0', marginBottom: '0', marginTop: '2.7rem', textOverflow: 'ellipsis', textWrap: 'nowrap' }}>
          {callName ? callName + ' - ' : ''}{steps[step].name ?? ''}
        </h5>
      </Grid>
    </>
  )
}

StepperComponent.defaultProps = {
  step: 0,
  updateStep: true,
}

export default StepperComponent;