
import { Alert, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../routes/routes";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from "react-i18next";
import * as APIServices from "../../services/rest/APIServices";
import ApplicationContext from "../../context/ApplicationContext";
import { SCROLLABLE_AREA_NAME, canUserEditInformation } from "../../utils/CommonFunctions";
import ULButton from "../../components/ULButton";
import Loading from "../../components/Loading";
import ModalityAndCareerContext from "../../context/ModalityAndCareerContext";

const ListCareers = (props) =>
{

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { applicationID } = useContext(ApplicationContext);
    const { setModalityID, careerID, setCareerID, setUniversityData } = useContext(ModalityAndCareerContext);
    const [careersList, setCareersList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessages, setErrMessages] = useState([]);
    const [working, setWorking] = useState(false);
    const [canEdit, setCanEdit] = useState(true);

    function saveModalityAndCareerInfo (e)
    {
        const data = {
            "applicationId": applicationID ?? 0,
            "callCareerId": careerID ?? 0,
        }
        setWorking(true);
        setErrMessages([]);
        APIServices.saveModalityAndCareerInfo(data)
            .then((response) =>
            {
                const requiresUniversity = careersList.find(item => item.callCareerId === careerID)?.requiresUniversity ?? false;
                if (requiresUniversity)
                    navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_TYPE);
                else
                    navigate(ROUTES.APPLICATION_SUMMARY);
            })
            .catch((error) =>
            {
                setErrMessages(error.response ? error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }] : [{ code: "", message: t('errors.network') }])
                const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .finally(() =>
            {
                setWorking(false);
            })
    }

    const handleContinue = (e) =>
    {
        const requiresUniversity = careersList.find(item => item.callCareerId === careerID)?.requiresUniversity ?? false;
        if (canEdit)
        {
            saveModalityAndCareerInfo(e);
        }
        else
        {
            if (requiresUniversity)
                navigate(ROUTES.MODALITY_AND_CARREERS_UNIVERSITY_TYPE);
            else
                navigate(ROUTES.APPLICATION_SUMMARY);
        }
    }

    const fetchData = async () =>
    {
        if (!applicationID) return;
        setIsLoading(true);
        await APIServices.fetchModalityAndCareerInfo(applicationID)
            .then((response) =>
            {
                const data = response.data.data;
                if (data)
                {
                    setCareerID(data.callCareerId ?? 0);
                    setModalityID(data.callModalityId ?? 0);
                    data.university && setUniversityData(data.university);
                    const status = response.data.data?.status;
                    setCanEdit(canUserEditInformation(status));
                }
            })
            .catch((error) =>
            {
                // No se pudo obtener la info (aún no ha sido registrada)
            })
            .finally(() =>
            {
                setIsLoading(false);
            })
    }

    useEffect(() =>
    {
        const callServices = async () =>
        {
            setIsLoading(true);
            await APIServices.getCareerList(applicationID).then((response) =>
            {
                setCareersList(response.data.data?.list ?? []);
            })
                .catch((error) =>
                {
                    setErrMessages(error.response ? (error.response.data?.messages ?? [{ code: "", message: t('errors.unknown') }]) : [{ code: "", message: t('errors.network') }]);
                    const scrollableArea = document.getElementById(SCROLLABLE_AREA_NAME);
                    scrollableArea.scrollTo({ top: 0, behavior: 'smooth' });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                })
                .finally(() => {
                    setIsLoading(false);
                })
            await fetchData();
        }
        callServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCareerChange = (e) =>
    {
        setCareerID(parseInt(e.target.value));
    }

    return (
        <>
            {
                isLoading ?
                    <>
                        <Loading />
                    </> :
                    <>
                        <Grid container direction="column" spacing={2}>
                            {
                                errMessages?.length ?
                                    <Grid item xs={12}>
                                        <Alert role="alert" severity="error">
                                            {
                                                errMessages.map((err, index) => (
                                                    <Typography key={index}> {err.code?.length ? `[${err.code}]` : ""} {err.message}</Typography>
                                                ))
                                            }
                                        </Alert>
                                    </Grid>
                                    : <></>
                            }
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h4" style={{ textDecoration: 'underline', fontWeight: '500' }}>
                                    {t('modalities-and-careers.careers-title')}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        value={careerID}
                                    >
                                        {
                                            careersList?.map((career, index) => (
                                                <FormControlLabel onChange={handleCareerChange} disabled={!canEdit} key={index} className="radio-button" value={career.callCareerId} control={<Radio />} label={career.name} />
                                            ))
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton
                                    working={working}
                                    onClick={handleContinue}
                                    disabled={!careerID}
                                    fullWidth
                                >
                                    {t('buttons.continue')}
                                </ULButton>
                            </Grid>

                            <Grid item xs={12}>
                                <ULButton
                                    variant="outlined"
                                    onClick={
                                        () => navigate(ROUTES.MODALITY_AND_CARREERS_CHOOSE_MODALITY)
                                    }
                                    fullWidth
                                >
                                    {t('buttons.back')}
                                </ULButton>
                            </Grid>

                        </Grid>
                    </>
            }
        </>
    )
}

export default ListCareers;